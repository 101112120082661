import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import { Button, Typography } from 'amphitheatre';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import SEO from 'src/Components/SEO';

import * as Base from 'src/page-styles/base.styled';

import LogoQFB from 'src/images/initiatives/qfb.png';
import LogoSACT from 'src/images/initiatives/sact.jpg';
import LogoWolfpack from 'src/images/initiatives/wolfpack.png';

const { Header } = Typography;

interface Logo {
  src: string;
}

const Content = styled.div`
  @media only screen and (min-width: 1024px) {
    padding: 1rem 2rem;
  }
`;

const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.grey700};
  margin: 3rem 0;
`;

const Logo = styled.div<Logo>`
  background: url(${({ src }) => src});
  background-size: cover;
  background-position: 50% 50%;

  height: 196px;
  min-height: 196px;
  width: 196px;
  min-width: 196px;

  margin: auto;

  @media only screen and (min-width: 1024px) {
    height: 256px;
    min-height: 256px;
    width: 256px;
    min-width: 256px;
  }
`;

const InitiativesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-top: 2rem;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

interface Props {
  site: {
    siteMetadata: {
      rawURL: string;
      wolfpackURL: string;
    };
  };
}

function Initiatives({ site }: Props) {
  const { rawURL, wolfpackURL } = site.siteMetadata;
  return (
    <>
      <SEO
        title="Community Engagement | Saber Astronautics"
        description="Looking to set up the foundations of the NewSpace Community, Saber frequently encourages engagement and collaboration our initiatives."
        url="https://www.saberastro.com/engagements"
      />
      <Layout>
        <Container.Dark>
          <Base.MenuGap />
          <Base.Heading>SABER ENGAGEMENT</Base.Heading>
          <Base.Text>
            <Base.Paragraph>
              Inline with Saber Astronautic's goals of democratisation of space
              exploration. We have hosted a number of initiatives to help lower
              the barrier of entry into the space industry.
            </Base.Paragraph>
          </Base.Text>
          <br />
          <InitiativesWrapper>
            <Logo src={LogoWolfpack} />
            <Content>
              <Header level={3}>Wolfpack</Header>
              <Base.Paragraph>
                Launched in December 2019, the Wolfpack Space Hub is a space
                focused incubator providing support, training, advice and
                industry relationships. Wolfpack is jointly supported by Saber
                Astronautics and TCG Corporation in a bid to seed the Australian
                space industry.
              </Base.Paragraph>
              <Base.Paragraph>
                Saber’s expertise in space mission design and operations as well
                as our strong relationships within the industry uniquely
                qualifies us to be able to provide the support and advice
                aspiring space startups might not realise they’re looking for
              </Base.Paragraph>
              <a
                href={`http://${wolfpackURL}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button fill>Find out more about it here.</Button>
              </a>
            </Content>
          </InitiativesWrapper>
          <Divider />
          <InitiativesWrapper>
            <Logo src={LogoSACT} />
            <Content>
              <Header level={3}>SACT</Header>
              <Base.Paragraph>
                The Sprint Advanced Concept Training (SACT) event started its
                life as a classified military space operations event run by the
                NSDC on a quarterly basis. In 2019, the SACT was run at the
                Unclassified level for the first time, out of the Sensitive
                compartmented information facility (SCIF) at Catalyst Campus
                (Colorado Springs).
              </Base.Paragraph>
              <Base.Paragraph>
                Saber Astronautics was one of a handful of companies who were
                exposed to the burgeoning field of Space Traffic Management &
                Protect and Defend Missions that were being run by the US
                Millitary. Since 2019, Saber Astronautics has played a key role
                in every SACT event, developing technologies around
                visualization and providing mission management experience.
              </Base.Paragraph>
            </Content>
          </InitiativesWrapper>
          <Divider />
          <InitiativesWrapper>
            <Logo src={LogoQFB} />
            <Content>
              <Header level={3}>Quest For Blue</Header>
              <Base.Paragraph>
                The inaugural Quest competition was hosted during October 2020
                and involved over 200 participants from 20 universities around
                the globe. The competition provided access to{' '}
                <Link to="/pigi">P.I.G.I.</Link>, and provided participants with
                a chance to experience their first attempt at mission design.
                The visualisation components of P.I.G.I. were very useful in
                allowing budding entrepreneurs grasp how minor changes have a
                ripple effect on their space mission and will change the scope
                dramatically.
              </Base.Paragraph>
              <Base.Paragraph>
                Quest For Blue was designed to help bring innovative ideas and
                aspirations to life, making them tangible and therefore
                refinable more than just a pipedream.
              </Base.Paragraph>
              <Base.Gap />
              <a
                href={`http://questforblue.${rawURL}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button fill>Quest For Blue</Button>
              </a>
            </Content>
          </InitiativesWrapper>
        </Container.Dark>
      </Layout>
    </>
  );
}

export default function (): JSX.Element {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              rawURL
              wolfpackURL
            }
          }
        }
      `}
      render={({ site }) => <Initiatives site={site} />}
    />
  );
}
